// this stays here (vs going into prism) since it is unique to this property
import {
  AnalyticsEvents,
  AnalyticsInteractionTypes,
  AnalyticsToolNames,
  GTMDataBuilder, pushToDataLayer
} from '@prism/toolkit'

export const ANALYTICS_FILTER_TYPES = Object.freeze({
  PEEL_AND_STICK: 'peel_and_stick'
})

function _trackColorFamilyOrCollection (label, isColorFamily = true) {
  const gtmDataBuilder = new GTMDataBuilder()
  gtmDataBuilder.setEvent(AnalyticsEvents.colorTool)
  gtmDataBuilder.setToolName(AnalyticsToolNames.colorWall)
  if (isColorFamily) {
    gtmDataBuilder.setColorFamily(label)
  } else {
    gtmDataBuilder.setColorCollection(label)
  }
  gtmDataBuilder.setInteractionType(AnalyticsInteractionTypes.filter)

  pushToDataLayer(gtmDataBuilder.getData())
}

export function trackColorFamily (label) {
  _trackColorFamilyOrCollection(label, true)
}

export function trackColorCollection (label) {
  _trackColorFamilyOrCollection(label, false)
}
