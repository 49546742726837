import React from 'react'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { ColorSwatch, pushToDataLayer, GTMDataBuilder, AnalyticsEvents, AnalyticsInteractionTypes, AnalyticsToolNames } from '@prism/toolkit'
import { setSeeOptions } from 'app/Store/actions/cart'
import archivedColors from './archived-colors'
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SwatchContentRenderer = ({ color }) => {
  const dispatch = useDispatch()
  const isUnavailable = archivedColors.has(`${color.colorNumber}`)
  return <>
    <ColorSwatch.Title name={color.name} number={`${color.brandKey} ${color.colorNumber}`} />
    <div style={{ marginTop: 'auto' }}>
      {isUnavailable
        ? <ColorSwatch.Message><FormattedMessage id='COLOR_CHIP_NOT_AVAILABLE' /></ColorSwatch.Message>
        : <button
          className='flex items-center ring-primary focus:outline-none focus:ring-2'
          onClick={() => {
            const gtmBuilder = new GTMDataBuilder()
            gtmBuilder.setEvent(AnalyticsEvents.cta)
            gtmBuilder.setToolName(AnalyticsToolNames.colorWall)
            gtmBuilder.setColorId(`${color.brandKey}${color.colorNumber}`)
            gtmBuilder.setColorName(color.name)
            gtmBuilder.setLinkText('order samples')
            gtmBuilder.setInteractionType(AnalyticsInteractionTypes.cta)
            const gtmData = gtmBuilder.getData()

            pushToDataLayer(gtmData)
            dispatch(setSeeOptions(color))
          }
          }>
          <FontAwesomeIcon icon={faPlusCircle} />
          <div className='text-xs rounded p-1 leading-none'>
            <FormattedMessage id='TEXT_SEE_OPTIONS' className='text-xs opacity-90 ml-2' />
          </div>
        </button>}
    </div>
  </>
}

export default SwatchContentRenderer
