function makeFamilyFilter (family) {
  return function (color = {}) {
    const { colorFamilyNames = [] } = color
    return colorFamilyNames.indexOf(family) > -1
  }
}

// @todo right now these values do not change by language, if they ever do, these will break -RS  (Amended by TF after adding Historic and Timeless)
export const isColorEde = makeFamilyFilter('Designer Color Collection')
export const isColorHistoric = makeFamilyFilter('Historic Color')
export const isColorTimeless = makeFamilyFilter('Timeless Color')
