/* eslint-disable */
import './Cart.scss'
import React, { useEffect, useCallback, useMemo, useRef, useState, useContext } from 'react'
import isEmpty from 'lodash/isEmpty'
import CartBlock, { DISPLAY_MODES } from './CartBlock'
import useCart from 'app/hooks/useCart'
import { LIMIT_CART_CHIP } from 'app/constants/product'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import Disclaimer from '../Disclaimer/Disclaimer'
import { isColorEde } from 'app/Utils/isColorFamily.util'
import { Link } from 'react-router-dom'
import usePrependPath from 'app/hooks/usePrependPath'
import { AutoSizer } from 'react-virtualized'
import Axios from 'axios'
import debounce from 'lodash/debounce'
import noop from 'lodash/noop'
import { INTENTIONAL_CANCELLATION } from 'app/Utils/ajax.util'
import Callout from '../Callout/Callout'
import countries from 'app/constants/countries'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import useSnackbar from 'app/hooks/useSnackbar'
import Snackbar from 'components/Snackbar/Snackbar'
import ProductSection from 'components/Cart/ProductSection'
import PeelStickIcon from 'components/PeelStickIcon/PeelStickIcon'
import ChipsIcon from 'components/ChipsIcon/ChipsIcon'

// @todo CANCELTOKENS ARE DEPRECATED -RS
const CancelToken = Axios.CancelToken

const ROW_CHIP_MAX = 5
const ROW_SWATCH_MAX = 4
const ID_SWATCH_CART_BLOCK = 'cart-block-swatches'
const ID_CHIP_CART_BLOCK = 'cart-block-chips'
const ID_CSIRIANO_CART_BLOCK = 'cart-block-kits'

function Cart () {
  const {
    itemsChips,
    itemsChipsNonZero,
    itemsSwatches,
    itemsSwatchesNonZero,
    nonZeroItems,
    itemsKits,
    itemsKitsNonZero,
    sync,
    slowSync,
    add,
    canAddChips,
    errors,
    itemsRequiredForDiscount,
    swatchDiscountedPrice
  } = useCart()
  const { openSnackbar, closeSnackbar } = useSnackbar()
  const priceSwatch = useMemo(() => itemsSwatchesNonZero.map(({ price }) => price)[0], [itemsSwatchesNonZero])
  const priceChip = useMemo(() => itemsChipsNonZero.map(({ price }) => price)[0], [itemsChipsNonZero])
  const priceKit = useMemo(() => itemsKitsNonZero?.map((item) => item?.price)[0], [itemsKitsNonZero]) || null
  const hasEde = useMemo(() => nonZeroItems.map(({ colors = [] }) => isColorEde(colors[0])).filter(Boolean).length > 0, [nonZeroItems])
  const prependPath = usePrependPath()
  const cancelToken = useRef(CancelToken.source())
  const errorRef = useRef()
  const scrollToError = useCallback(debounce(() => errorRef?.current?.focus(), 100), [])
  // the counts below are used to determine if the discount can be applied
  const swatchCount = itemsSwatchesNonZero.reduce((accum, next) => accum + (next?.qty ?? 0), 0)
  const kitCount = itemsKitsNonZero.reduce((accum, next) => accum + (next?.qty ?? 0), 0)
  const [displayMode, setDisplayMode] = useState(DISPLAY_MODES.NONE)
  const { country } = useContext(CountryLangCtx)
  const isUS = country === countries.us

  const { formatMessage } = useIntl()

  useEffect(() => {
    sync(true, cancelToken.current.token, () => { }, err => {
      if (err) {
        // real problem, not user-cancellation
        console.warn('Unable to perform initial sync', err)
      }
    })

    return () => {
      slowSync.cancel() // cancel any hanging debounced syncs
      cancelToken.current.cancel(INTENTIONAL_CANCELLATION)
    }
  }, [])

  useEffect(() => { if (errors?.length) { scrollToError() } }, [errors])

  const actionUndo = (onClick) => (
    <React.Fragment>
      <button aria-label='close' onClick={onClick}>
        Undo
      </button>
    </React.Fragment>
  )

  const handleQtyChange = useCallback((item, qty, absolute = false) => {
    const newQty = absolute ? qty - item.qty : qty
    // @todo its probably ok to use the constant for this since kits are a one-off (data models are very diff) -RS
    const itemName = item.isKit ? formatMessage({ id: 'CHRISTIAN_SIRIANO_KIT_DESC' }) ?? '' : item.colors[0].name

    if (item.qty + newQty === 0) {
      openSnackbar({
        message: `${itemName} has been removed.`,
        variant: Snackbar.VARIANT_DANGER,
        action: actionUndo(() => {
          add(item.productId, 1)
          slowSync(false, cancelToken.current.token, () => { }, err => {
            if (err) {
              console.warn('Unable to sync after undo', err)
            }
          })
          closeSnackbar()
        })
      })
    }

    add(item.productId, newQty)
    // update the server with the quantity
    // pass false so we don't clean up deleted items during slow sync
    slowSync(false, cancelToken.current.token, () => { }, err => {
      if (err) {
        // real problem, not user-cancellation
        console.warn('Unable to sync quantity updates', err)
      }
    })
  }, [])

  if (isEmpty(itemsChipsNonZero) && isEmpty(itemsSwatchesNonZero) && isEmpty(itemsKitsNonZero)) {
    return (
      isUS ? (<div className='empty-cart-message'>
        <FormattedMessage id='CHECKOUT_EMPTY_CART_MSG' values={{
          continueShoppingText: chunk => <p className='mb-4'>{chunk}</p>,
          continueShoppingCTA: chunk => <Link className='button is-dark' to={prependPath()}>{chunk}</Link>
        }} />

      </div>) : (
        <div className='message is-danger'>
          <div className='message-body'>
            <FormattedMessage id='CHECKOUT_EMPTY_CART_MSG' values={{
              continueShoppingText: chunk => <p className='mb-4'>{chunk}</p>,
              continueShoppingCTA: chunk => <Link className='button is-dark' to={prependPath()}>{chunk}</Link>
            }} />
          </div>
        </div>
      )
    )
  }

  return (<>
    <AutoSizer disableHeight style={{ width: '100%' }} onResize={({ width }) => setDisplayMode(width > 500 ? DISPLAY_MODES.LARGE : DISPLAY_MODES.SMALL)}>{noop}</AutoSizer>
    {errors.length
      ? (
        <article className='message is-danger' aria-live='assertive' tabIndex='0' role='alert' ref={errorRef}>
          <header className='message-header'>
            <p><FormattedMessage id='CART_ERROR_HEADING' /></p>
          </header>
          <div className='message-body'>
            <div className='content'>
              <ul className='mt-0'>
                {errors.map(v => <li key='v'>{v}</li>)}
              </ul>
            </div>
          </div>
        </article>
      )
      : null}
    {itemsChips.length ? (<article>
      <header className='mb-5' id={ID_SWATCH_CART_BLOCK}>
        <h2 className='title is-size-6 is-size-5-tablet mb-1 has-text-weight-semibold level is-mobile'>
          <p>
            <span className='level-left float-l'>
              <span className='level-item Cart__icon-wrapper'><ChipsIcon isOnWhiteBG /></span>
              <span className='level-item'><FormattedMessage id='2X3_CHIPS' values={{ count: 1 }} /><br />
                {priceChip === 0
                  ? <span className='level-item has-text-weight-bold is-uppercase'>&ensp;<FormattedMessage id='PRICE_FREE' /></span>
                  : priceChip
                    ? <span className='level-item has-text-weight-bold'>&ensp;<FormattedMessage id='PRICE_EACH' values={{ price: <FormattedNumber style='currency' value={priceChip} currency='USD' /> }} /></span>
                    : null}
              </span>
            </span>
          </p>
        </h2>
        {!canAddChips
          ? (<p className='has-text-danger'>
            <FormattedMessage id='CART_ERROR_MAX_LIMIT' values={{
              count: <FormattedMessage id='X_COLOR_CHIPS' values={{ count: LIMIT_CART_CHIP }} />
            }} />
          </p>)
          : null
        }
      </header>
      <CartBlock className='mb-6' aria-labelledby={ID_SWATCH_CART_BLOCK} tabIndex='0'>
        {itemsChips.map((item, i) => <CartBlock.Item displayMode={displayMode} onQtyChange={handleQtyChange} item={item} key={i} />)}
        {(new Array(Math.max(ROW_CHIP_MAX, ROW_CHIP_MAX % itemsChips.length)).fill(null)).map((item, i) => <CartBlock.Blank displayMode={displayMode} key={i} />)}
      </CartBlock>
    </article>) : null}
    {/* Section delimiter */}
    {itemsSwatches.length && isUS ? (<article>
      <header className='mb-5' id={ID_CHIP_CART_BLOCK}>
        <h2 className='title is-size-6 is-size-5-tablet has-text-weight-semibold level is-mobile mb-0 '>
          <span className='level-test' style={{ 'flexWrap': 'wrap', 'width': '100%' }}>
            <span className='level-item us-nonzeroitems is-narrow Cart__icon-wrapper'><PeelStickIcon isOnWhiteBG /></span>
            <span className='level-item us-nonzeroitems is-narrow'><FormattedMessage id='8X8_PEEL_AND_STICK' /></span>
            {priceSwatch
              ? <span className='level-item us-nonzeroitems is-narrow description'>
                <FormattedMessage id='PRICE_EACH_OR_4_PRICE' values={{
                  priceA: <FormattedNumber style='currency' value={priceSwatch} currency='USD' />,
                  wrapPriceA: chunk => <span className='has-text-weight-bold'>{chunk}&nbsp;</span>,
                  priceB: <FormattedNumber style='currency' value={swatchDiscountedPrice} currency='USD' />,
                  wrapPriceB: chunk => <span className='has-text-weight-bold has-text-green'>&nbsp;{chunk}</span>,
                  line: chunk => chunk
                }} />
              </span>
              : null}
          </span>
        </h2>
        {swatchCount === itemsRequiredForDiscount - 1
          ? <h3 className='is-size-6 is-size-5-tablet mt-3'>
            <Callout positive>Add just one more peel & stick sample &mdash; to unlock a lower price.</Callout>
          </h3>
          : null}
      </header>
      <CartBlock aria-labelledby={ID_CHIP_CART_BLOCK} tabIndex='0' className='mb-6'>
        {itemsSwatches.map((item, i) =>
          <CartBlock.Item
            displayMode={displayMode}
            onQtyChange={handleQtyChange}
            item={item}
            key={i}
          />
        )}
        {(new Array(Math.max(ROW_SWATCH_MAX, ROW_SWATCH_MAX % itemsSwatches.length)).fill(null)).map((item, i) => <CartBlock.Blank displayMode={displayMode} big key={i} />)}
      </CartBlock>
    </article>) : null}
    {/* Section delimiter */}
    <ProductSection
      usOnly
      handleQtyChange={handleQtyChange}
      rowMax={ROW_SWATCH_MAX}
      price={priceKit}
      products={itemsKits}
      cartBlockId={ID_CSIRIANO_CART_BLOCK}
      productCount={kitCount} />
    {hasEde
      ? <div>
        <Disclaimer
          showHeading
          className='has-background-white'
          media={<img src={require('src/images/emerald-designer-edition.png')} alt={formatMessage({ id: 'EMERALD_DESIGNER_EDITION' })} />}
          message={<FormattedMessage id='EDE_DISCLAIMER' />}
          heading={<FormattedMessage id='EDE_DISCLAIMER_TITLE' />} />
      </div>
      : null}
  </>)
}

export default Cart
