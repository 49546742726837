/* eslint-disable */
import React from 'react'

import './Confirmation.scss'
import useQueryParamOrderNumber from 'src/app/hooks/useQueryParamOrderNumber'
import { FormattedMessage } from 'react-intl'

const classBase = 'confirm'

function Confirmation () {
  const [orderNumber, hasNoOrderNumber] = useQueryParamOrderNumber()

  if (hasNoOrderNumber) {
    return (
      <section className={`${classBase}__message`}>
        <h1 className='title is-size-4-touch'><FormattedMessage id='ORDER_CANT_FIND_HEADING' /></h1>
        <h2 className='is-size-6 mb-5'><FormattedMessage id='ORDER_CANT_FIND_MESSAGE' /></h2>
      </section>
    )
  }

  return (
    <>
      <section className={`${classBase}__message`}>
        <h1 className='title is-size-4-touch'><FormattedMessage id='ORDER_CONFIRMATION_HEADING' /></h1>
        <h2 className='is-size-6 mb-5'><FormattedMessage id='ORDER_CONFIRMATION_MSG' /></h2>
        <p className='has-text-weight-bold'>
          <FormattedMessage id='ORDER_REF_NUMBER' values={{ orderNumber }} />
        </p>
      </section>
    </>
  )
}

export default Confirmation
