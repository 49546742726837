import React, { useEffect, useState, useCallback, useRef, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import SVG from 'react-inlinesvg'
import debounce from 'lodash/debounce'
import { Address, Address2, City, Country, Postal, State } from '../OrderForm/Fields'
import Recaptcha from '../Recaptcha/Recaptcha'
import useCart from 'app/hooks/useCart'
import useEffectAfterMount from 'app/hooks/useEffectAfterMount'
import usePrependPath from 'app/hooks/usePrependPath'
import { setCustomErrors, setRecaptchaError, setIsLoading, resetState, setNoOrderError } from 'app/Store/slices/OrderForm'
import ArrowIcon from 'src/svg/arrow-right.svg'

import { submitOrder, updateOrderByOrderId } from 'app/Utils/orderSubmission.util'
import { INTENTIONAL_CANCELLATION } from 'app/Utils/ajax.util'
import extractUser, { validateUser } from 'app/Utils/user.util'
import { setAuthId } from 'app/Store/actions/cart'
import countries from 'app/constants/countries'
import CountryLangCtx from 'app/contexts/countryLang/CountryLangCtx'
import uniqueId from 'lodash/uniqueId'
import CardForm from 'components/PaymentForm/CardForm'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RecaptchaModalView from 'components/Recaptcha/RecaptchaModal'
import validationPatterns from 'app/constants/validationPatterns'

import './PaymentForm.scss'

const CancelToken = axios.CancelToken

const SECTIONS = {
  BILLING: 'billing',
  PAYMENT: 'payment'
}

function shouldEnablePaymentSection (openSection) {
  return openSection === SECTIONS.BILLING || !openSection
}

function PaymentForm ({ children }) {
  const [useShippingAddress, setUseShippingAddress] = useState(false)
  const { register, handleSubmit, errors, setValue, reset, clearError } = useForm()
  const { nonZeroItems, orderId, user, load, empty, errors: cartErrors, setUser } = useCart()
  const recaptchaRef = useRef()
  const errorRef = useRef()
  const prependPath = usePrependPath()
  const history = useHistory()
  const dispatch = useDispatch()
  const cancelToken = useRef(CancelToken.source())
  const { customErrors, recaptchaEnabled, recaptchaResponse, recaptchaError, emptyCartError, noPayableItemsError, noOrderError } = useSelector(state => state.orderForm)
  const [hasUserData, setHasUserData] = useState(validateUser(user))
  const { country } = useContext(CountryLangCtx)
  const authId = useSelector(store => store.authId)
  // we should not need the client token globally safe here
  const [clientTokenForRespURL, setClientTokenForRespURL] = useState('')
  const [showRetryButton, setShowRetryButton] = useState(false)
  // bc isLoading is really bound to the forms
  const [isBusy, setIsBusy] = useState(false)

  const intl = useIntl()
  const [openSection, setOpenSection] = useState(SECTIONS.BILLING)
  const [billingInfo, setBillingInfo] = useState(null)

  // use this ref to know where to scroll to
  const formWrapperRef = useRef(null)

  const submitSampleOrder = (orderId, cToken, authId) => {
    submitOrder(orderId, cToken.current.token, authId, intl, true).then((data) => {
      if (!data) {
        // handle use cancellation
        return setIsBusy(false)
      }
      empty(orderId)
      // redirect to confirmation page with the orderNumber
      // clear authid so this can only be called once
      dispatch(setAuthId())
      setIsBusy(false)
      history.replace(`${prependPath('confirmation')}?orderNumber=${data.orderId}`)
    }).catch((err) => {
      // if this is a 500 error allow the user to try to resubmit submission since they have an auth
      const foundRetryableError = err?.errors.find((msg) => {
        return `${msg}`.indexOf(intl.formatMessage({ id: 'ERROR_ORDER_RETRY_DETAIL' })) > -1
      })

      if (foundRetryableError) {
        setShowRetryButton(true)
      } else {
        setShowRetryButton(false)
      }
      broadcastCustomErrors([...err.errors])
      setIsBusy(false)
    })
  }

  const handleRetrySubmitOrder = (e) => {
    e.preventDefault()
    setIsBusy(true)
    submitSampleOrder(orderId, cancelToken, authId)
  }

  const broadcastCustomErrors = (errors) => {
    dispatch(setCustomErrors(errors))
  }

  const recaptchaRefAlpha = useRef(null)
  const recaptchaRefBeta = useRef(null)

  useEffectAfterMount(() => { setHasUserData(validateUser(user)) }, [user])

  useEffect(() => {
    reset() // reset the form

    // reset related redux on arrive at page
    dispatch(resetState())

    // unmount the refs when PaymentForm is unmounted
    return () => {
      recaptchaRef.current = undefined
      recaptchaRefAlpha.current = undefined
      recaptchaRefBeta.current = undefined
      errorRef.current = undefined
      criticalErrorsRef.current = undefined
      cancelToken.current.cancel(INTENTIONAL_CANCELLATION)
      dispatch(setAuthId(''))
      clearError()
    }
  }, [])

  const criticalErrorsRef = useRef([])
  criticalErrorsRef.current = [
    emptyCartError,
    noPayableItemsError,
    noOrderError,
    !hasUserData
  ].filter(Boolean)

  const scrollToError = useCallback(debounce(() => {
    const { current } = errorRef
    if (current) {
      current.focus()
    }
  }, 100), [])

  // While developing recaptcha will make the UI jump since there will be an order error in the pipes
  // @todo I don't think we need all of these -RS
  useEffect(() => {
    if ([
      ...(cartErrors ?? []),
      ...(customErrors ?? []),
      emptyCartError,
      noPayableItemsError,
      recaptchaError,
      noOrderError,
      !hasUserData
    ].filter(Boolean).length) {
      scrollToError()
    }
  }, [cartErrors, customErrors, emptyCartError, noPayableItemsError, recaptchaError, noOrderError, hasUserData])

  // recaptcha ref handling: we do this since we have 2 different DOM elements that represent the recaptcha
  // 1)billing section recaptcha and 2) modal recptcha for the  payment section.
  // Since the recaptcha logic works we don't want to touch it, we extend it by managing the refs it uses
  useEffect(() => {
    if (openSection === PaymentForm) {
      recaptchaRef.current = recaptchaRefBeta.current
    } else {
      recaptchaRef.current = recaptchaRefAlpha.current
    }
  }, [recaptchaError, openSection])

  // The app responds to receiving and authid; if there are no recaptcha errors then the order submits
  useEffect(() => {
    if (recaptchaEnabled && !recaptchaResponse) {
      return
    }

    if (authId && orderId && !showRetryButton) { // if retry button is show this should imperatively submit instead of reactively submitting
      setIsBusy(true)
      submitSampleOrder(orderId, cancelToken, authId)
    }
  }, [recaptchaEnabled, recaptchaResponse, authId, orderId, showRetryButton])

  const hasLoaded = useRef(false)
  useEffect(() => {
    if (orderId && nonZeroItems.length && !hasLoaded.current) {
      load(intl)
      hasLoaded.current = true
    }
  }, [orderId, nonZeroItems])

  useEffect(() => {
    // check for order ID, if not throw error and do not sync
    // custom error with link back to homepage
    // do the same thing on payment page? maybe?
    dispatch(setNoOrderError(!orderId))
  }, [orderId])

  // @todo - form creation happened here in a hook -RS

  // set shipping address into billing address fields
  const handleUseShippingAddress = (e) => {
    const { address1, address2, city, country, postal, state } = user
    const target = e.target
    const value = target.type === 'checkbox' ? target.checked : target.value

    setUseShippingAddress(value)

    if (!value) {
      reset() // resetting _everything_ in order to nullify error states if they exist prior to populating
      return
    }

    clearError()

    setValue('address1', address1)
    setValue('address2', address2)
    setValue('city', city)
    setValue('country', country)
    setValue('postal', postal)
    setValue('state', state)
  }

  const getSectionClassName = (openSect, thisSect) => {
    return openSect === thisSect ? 'payment-form__section--visible' : 'payment-form__section--hidden'
  }

  const openBilling = (e) => {
    e.preventDefault()
    const newSection = openSection !== SECTIONS.BILLING ? SECTIONS.BILLING : ''
    setOpenSection(newSection)
    if (newSection) {
      scrollToFormTop()
    }
  }

  const openPayment = (e) => {
    e.preventDefault()
    const newSection = openSection !== SECTIONS.PAYMENT ? SECTIONS.PAYMENT : SECTIONS.BILLING
    setOpenSection(newSection)
    if (newSection) {
      scrollToFormTop()
    }
  }

  const scrollToFormTop = () => {
    setTimeout(() => {
      const { x, y } = formWrapperRef.current.getBoundingClientRect()
      window.scrollTo(x, y)
    }, 100)
  }

  const handlePaymentFormError = (err) => {
    const errMsg = err?.message
    console.error(errMsg)
    broadcastCustomErrors([`${intl.formatMessage({ id: 'PAYMENT_ERROR_GENERIC_TITLE' })}. ${intl.formatMessage({ id: 'PAYMENT_ERROR_GENERIC_DETAIL' })}`])
  }

  const saveBilling = (formData) => {
    // clear any previous errors
    broadcastCustomErrors(null)
    if (recaptchaEnabled && !recaptchaResponse) {
      dispatch(setRecaptchaError(true))
    }

    // this preloads the ifrmae
    setBillingInfo(formData)
    dispatch(setIsLoading(true))
    const { newsletterOptIn } = user
    updateOrderByOrderId(orderId, { newsletterOptIn }, cancelToken.current.token, intl).then((data) => {
      // this reveals the iframe
      if (data) {
        setClientTokenForRespURL(data.clientToken)
        setUser(extractUser(data))
        dispatch(setIsLoading(false))
      }
      setOpenSection(SECTIONS.PAYMENT)
      // eslint-disable-next-line n/handle-callback-err
    }).catch((err) => {
      dispatch(setIsLoading(false))
      broadcastCustomErrors([...err.errors])
    })
  }

  const shouldShowRecaptchaModal = (openSect, recapErr) => {
    if (openSect === SECTIONS.PAYMENT && recapErr) {
      return true
    }
  }

  const billingIsOpen = openSection === SECTIONS.BILLING

  return (
    <div ref={formWrapperRef}>
      {/* OVERALL ERROR MESSAGE */}
      {/* I need to trace the "user/userData object  to know what global role it plays */}
      {(customErrors?.length || (recaptchaError && billingIsOpen) || emptyCartError || cartErrors?.length || noOrderError || noPayableItemsError || !hasUserData)
        ? (
          <article className='message is-danger' aria-live='assertive' tabIndex='0' role='alert' ref={errorRef}>
            <header className='message-header'>
              <p><FormattedMessage id='CHECKOUT_ERRORS_HEADING' /></p>
            </header>
            <div className='message-body'>
              <div className='content'>
                <ul className='mt-0'>
                  {noOrderError
                    ? <li>
                      <FormattedMessage id='CHECKOUT_NO_ORDER_MSG' values={{
                        cartCTA: chunk => <><br /><Link className='has-text-link' to={prependPath('cart')}>{chunk}</Link></>
                      }} />
                    </li>
                    : emptyCartError
                      ? <li>
                        <FormattedMessage id='CHECKOUT_EMPTY_CART_MSG' values={{
                          continueShoppingText: chunk => <p className='mb-4'>{chunk}</p>,
                          continueShoppingCTA: chunk => <Link className='button is-dark' to={prependPath()}>{chunk}</Link>
                        }} />
                      </li>
                      : noPayableItemsError
                        ? <li>
                          <FormattedMessage id='CHECKOUT_NO_PAYABLE_ITEMS_MSG' values={{
                            cartCTA: chunk => <><br /><Link className='has-text-link' to={prependPath('cart')}>{chunk}</Link></>
                          }} />
                        </li>
                        : !hasUserData
                            ? <li>
                            <FormattedMessage id='CHECKOUT_UPDATE_SHIPPING_ADDRESS_MSG' values={{
                              shippingCTA: chunk => <><br /><Link className='has-text-link' to={prependPath('checkout')}>{chunk}</Link></>
                            }} />
                          </li>
                            : null}
                  {customErrors && customErrors.map((err) => (
                    <li key={uniqueId('errors-')}>{err}</li>
                  ))}
                  {cartErrors && cartErrors.map((err) => (
                    <li key={uniqueId('errors-')}>{err}</li>
                  ))}
                  {recaptchaError && <li><FormattedMessage id='CHECKOUT_ERRORS_SOLVE_RECAPTCHA' /></li>}
                </ul>
              </div>
            </div>
          </article>
          )
        : null}
      {/* END OVERALL ERROR MESSAGE */}
      <div>
        <div><button disabled={billingIsOpen} className='payment-form__section__header' onClick={openBilling}>
          <FormattedMessage id='BILLING_ADDRESS' />
          <span className='payment-form__section__header__chevron'><FontAwesomeIcon icon={billingIsOpen ? faChevronUp : faChevronDown} /></span>
        </button>
        </div>
        <div className={getSectionClassName(openSection, SECTIONS.BILLING)}>
          <form id='form' className='PaymentForm' onSubmit={handleSubmit(saveBilling)}>
            <div className='is-flex is-flex-wrap-wrap is-justify-content-space-between is-align-items-center mt-2 mb-2'>
              <div className='level is-mobile my-3'>
                <label className='level-left'>
                  <div className='level-item'>
                    <input
                      name='useShippingAddress'
                      type='checkbox'
                      disabled={!hasUserData}
                      checked={useShippingAddress}
                      onChange={handleUseShippingAddress} />
                  </div>
                  <div className='level-item'>
                    <FormattedMessage id='PAYMENT_FORM_SAME_ADDRESS' />
                  </div>
                </label>
              </div>
            </div>
            <div className='columns'>
              <div className='column'>
                <Address errors={errors} registerAs='address1' register={register({
                  required: intl.formatMessage({ id: 'BILLING_ADDRESS1_REQ' }),
                  pattern: {
                    value: validationPatterns.ADDRESS,
                    message: intl.formatMessage({ id: 'BILLING_ADDRESS1_INVALID' })
                  }
                })} />
              </div>
              <div className='column is-one-third-tablet'>
                <Address2 registerAs='address2' errors={errors} register={register({
                  pattern: {
                    value: validationPatterns.ADDRESS2,
                    message: intl.formatMessage({ id: 'CHECKOUT_FIELDS_ADDRESS2_ERROR_INVALID' })
                  }
                })} />
              </div>
            </div>
            <div className='columns'>
              <div className='column'>
                <City registerAs='city' errors={errors} register={register({
                  required: intl.formatMessage({ id: 'BILLING_CITY_REQ' }),
                  pattern: {
                    value: validationPatterns.CITY,
                    message: intl.formatMessage({ id: 'BILLING_CITY_INVALID' })
                  }
                })} />
              </div>
            </div>
            <div className='columns is-multiline'>
              <div className='column is-full is-one-third-widescreen'>
                <Country errors={errors} registerAs='country' register={register({ required: intl.formatMessage({ id: 'CHECKOUT_FIELDS_COUNTRY_ERROR_REQUIRED' }) })} />
              </div>
              <div className='column is-two-thirds-widescreen'>
                <div className='columns is-mobile'>
                  <div className='column is-half-mobile'>
                    <State errors={errors} registerAs='state' register={register({ required: intl.formatMessage({ id: 'CHECKOUT_FIELDS_STATE_ERROR_REQUIRED' }) })} />
                  </div>
                  <div className='column is-half-mobile'>
                    <Postal errors={errors} registerAs='postal' register={register({
                      required: intl.formatMessage({ id: 'BILLING_POSTAL_REQ' }),
                      pattern: {
                        value: (country.toLowerCase() === countries.ca
                          ? validationPatterns.POSTAL_CA
                          : validationPatterns.POSTAL_US
                        ),
                        message: intl.formatMessage({ id: 'BILLING_POSTAL_INVALID' })
                      }
                    })} />
                  </div>
                </div>
              </div>
            </div>
            {/* <div>
              {children}
            </div> */}
            {/* recaptcha needs to go here since we cannot validate it without our submit delegate (the save billing button) */}
            <div className='payment-form__recaptcha-wrapper'>
              <Recaptcha ref={recaptchaRefAlpha} />
            </div>
            <div className='is-flex is-flex-direction-row is-flex-wrap-wrap-reverse is-justify-content-flex-end'>
              <button disabled={!orderId} type='submit' className='button is-dark is-uppercase'><FormattedMessage id='CHECKOUT_BILLING_SAVE' /></button>
            </div>
          </form>
        </div>
      </div>
      <div>
        <div>
          <button disabled={shouldEnablePaymentSection(openSection)} className='payment-form__section__header' onClick={openPayment}>
            <FormattedMessage id='PAYMENT' />
            <span className='payment-form__section__header__chevron'>
              <FontAwesomeIcon icon={openSection === SECTIONS.PAYMENT ? faChevronUp : faChevronDown} />
            </span>
          </button></div>
        <div className={getSectionClassName(openSection, SECTIONS.PAYMENT)}>
          <div className='field mb-5'>
            <div className='payment-form__summary'>
              {children}
            </div>
            <div className='payment-form__card-form-wrapper'>
              {/* should not render until billing is set, after payment accordion opens */}
              {billingInfo && clientTokenForRespURL && orderId && !showRetryButton ? <CardForm billingAddress={billingInfo} handlePaymentError={handlePaymentFormError} orderId={orderId} title='Card Form' /> : null}
              {showRetryButton
                ? <div className='payment-form__card-retry-wrapper'>
                <button disabled={isBusy} className='button is-dark is-uppercase' onClick={handleRetrySubmitOrder}><FormattedMessage id='ORDER_RESUBMIT' /></button>
              </div>
                : null}
            </div>
          </div>
        </div>
        <div className='is-flex is-flex-direction-row is-flex-wrap-wrap-reverse is-justify-content-flex-end mt-5'>
          <Link style={{ order: '-1' }} className='button ml-3 mb-3' to={prependPath('checkout')}>
            <span className='icon is-small'>
              <SVG aria-hidden='true' className='svg-outline is-flip-x' src={ArrowIcon} />
            </span>
            <span className='is-uppercase'><FormattedMessage id='PAYMENT_FORM_BACK' /></span>
          </Link>
        </div>
      </div>
      <RecaptchaModalView showModal={shouldShowRecaptchaModal(openSection, recaptchaError)} ref={recaptchaRefBeta} />
    </div>
  )
}

export default PaymentForm
